// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// Relative
import Styled from '../../Styled';

const ItemContainer = Styled.View<{
  spaceBetweenItems: number;
  isHorizontal?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: ${({spaceBetweenItems, isHorizontal}) => (isHorizontal ? 0 : spaceBetweenItems)}px;
  margin-right: ${({spaceBetweenItems, isHorizontal}) => (isHorizontal ? spaceBetweenItems : 0)}px;
`;

const DragIcon = ({isDisabled, hasMargin = true}: {isDisabled?: boolean; hasMargin?: boolean}) => {
  return (
    <Icon
      source={Icon.GripVertical}
      color={isDisabled ? colors.gray.tertiary : colors.gray.secondary}
      size={Icon.Sizes.Large}
      style={{marginRight: hasMargin ? 10 : 0}}
    />
  );
};

export default {
  ItemContainer,
  DragIcon,
};
